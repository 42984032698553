// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
//= require jquery3
//= require popper
//= require bootstrap-sprockets

// import 'bootstrap'
// import "@hotwired/turbo-rails"
// import "controllers"
console.log("WELCOME!")

import './packs/application.js';
// import './packs/guest_new.js';