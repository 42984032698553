import { registerOnChangeElement } from "./common_guest"
import * as jQuery from 'jquery';
import * as $ from 'jquery';

jQuery(window).on('load', function () {
  registerOnChangeElement(".checkin-prop", onCheckinChanged, true);
  registerOnChangeElement(".nights-prop", onCheckoutChanged, true);
  registerOnChangeElement(".nights-prop", updateNumberOfNightsIfLessThanOne, true);
});

function addDays(date, days) {
  var result = date ? new Date(date) : new Date();
  result.setDate(result.getDate() + Number(days));
  return result;
}

function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

function onCheckinChanged(value) {
  const numberOfNights = $(".nights-prop")[0].value;
  updateCheckout(value, numberOfNights);
}

function onCheckoutChanged(value) {
  const startDate = $(".checkin-prop")[0].value;
  updateCheckout(startDate, value);
}

function updateCheckout(startDate, numberOfNights) {
  $(".checkout-prop")[0].value = dateToYMD(addDays(startDate, numberOfNights));
}

function updateNumberOfNightsIfLessThanOne() {
  const numberOfNights = Number($(".nights-prop")[0].value);
  if (numberOfNights <= 0) {
    $(".nights-prop")[0].value = 1;
    const startDate = $(".checkin-prop")[0].value;
    updateCheckout(startDate, 1);
  }
}
