import { registerOnChangeElement, registerOnFocusOut } from "./common_guest"
import * as jQuery from 'jquery';
import * as $ from 'jquery';

jQuery(window).on('load', function () {
    registerOnChangeElement("#guest_nationality", onNationalityChanged);
    registerOnChangeElement("#guest_current_country", onCurrentCountryChanged);
    registerOnChangeElement("#guest_current_city", onCurrentCityChanged, true);

    registerOnFocusOut("#guest_id_code", onGuestIdCodeFocusOut)
    registerOnFocusOut("#guest_id_type", onGuestIdTypeFocusOut)
});

function onNationalityChanged(current, previous) {
    const nationality = current;
    const currentCountry = $("#guest_current_country").val();
    const birthCountry = $("#guest_birth_country").val();

    if (currentCountry == previous && birthCountry == previous) {
        $("#guest_current_country").val(nationality);
        $("#guest_birth_country").val(nationality);
        onCurrentCountryChanged(nationality);
    }
}

function onCurrentCountryChanged() {
    const currentCountry = $("#guest_current_country").val();
    const birthCountry = $("#guest_birth_country").val();

    if (currentCountry == "PT" && birthCountry == "PT") {
        $(".birth_country-row").hide();
    } else {
        $(".birth_country-row").show();
    }
}

function onCurrentCityChanged() {
    const currentCountry = $("#guest_current_country")[0].value;
    if (currentCountry == "PT") {
        $("#guest_birth_country").val(currentCountry);
        $("#guest_birth_city").val($("#guest_current_city").val());
    }
}

function onGuestIdCodeFocusOut(currentValue, oldValue) {
    if (currentValue != oldValue) {
        const type = $("#guest_id_type").val();

        requestGuest(type, currentValue)
    }
}

function onGuestIdTypeFocusOut(currentValue, oldValue) {
    if (currentValue != oldValue) {
        const code = $("#guest_id_code").val();
        requestGuest(currentValue, code)
    }
}

function requestGuest(type, code) {
    $.get(`/guests/type/${type}/code/${code}.json`, (data) => {
        if (data != null && data._id != null && data._id.$oid) {
            const id = data._id.$oid;

            const guestIdCodeElement = $("#guest_id_code");
            const guestIdTypeElement = $("#guest_id_type");

            guestIdCodeElement.addClass("is-invalid");
            guestIdTypeElement.addClass("is-invalid");
            $(".invalid-feedback-guest_id_code").remove();
            guestIdCodeElement.after(`<div class="invalid-feedback invalid-feedback-guest_id_code">Este registo já existe. <a href="/guests/${id}">Clique aqui para ir para a ficha de cliente.</a></div>`);
        }
    }).fail(function () {
        const guestIdCodeElement = $("#guest_id_code");
        const guestIdTypeElement = $("#guest_id_type");
        guestIdCodeElement.removeClass("is-invalid");
        guestIdTypeElement.removeClass("is-invalid");

        $(".invalid-feedback-guest_id_code").remove();
    })
}
