import * as jQuery from 'jquery';
import * as $ from 'jquery';

jQuery(window).on('load', function () {
    $("input,select, textarea").each(function () {
        var theValue = $(this).val();
        $(this).data("value", theValue);
    });
});

jQuery.exists = function (selector) { return ($(selector).length > 0); }

// var pushState = history.pushState;
// history.pushState = function () {
//     pushState.apply(history, arguments);
//     var path = arguments[2].getPath()
//     if (path.startsWith("/guests/")) {
//         location.reload();
//     }
// };

const waitForEl = function (selector, callback, count) {
    if ($(selector)[0] != null) {
        callback();
    } else {
        setTimeout(function () {
            if (!count) {
                count = 0;
            }
            count++;
            if (count < 10) {
                waitForEl(selector, callback, count);
            }
        }, 1000);
    }
};

export const registerOnChangeElement = function (elementName, fn, onlyOnChange = false) {
    console.log(`Registering ${elementName}...`);
    const elem = $(elementName);
    waitForEl(elementName, function () {

        console.log("Registering new");
        elem.on("change", function () {
            var previousValue = $(this).data("value");
            var currentValue = $(this).val();
            $(this).data("value", currentValue)
            fn(currentValue, previousValue)
        });
        if (!onlyOnChange) {
            fn();
        }
        console.log(`Registered ${elementName}...`);
    });
}

export const registerOnFocusOut = function (elementName, fn) {
    console.log(`Registering ${elementName}...`);
    const elem = $(elementName);
    waitForEl(elementName, function () {

        console.log("Registering new");
        elem.on("focusout", function () {
            var previousValue = $(this).data("value");
            var currentValue = $(this).val();
            $(this).data("value", currentValue)
            fn(currentValue, previousValue)
        });
        console.log(`Registered ${elementName}...`);
    });
}
